import { Button, Form, Input, List, Spin } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import DesktopSignupModal from "@/pages/Login/desktop-singup-modal";
import { ProjectContext } from "@/common/project-provider";
import api from "@/api/api";
import instance from "@/api/instance";
import { removeSessionStorage } from "@/common/util";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { defaultColor } from "@/styles/color";
import { ThemeContext } from "@/common/theme-provider";
import Modal from "@/components/modal";
import { useTranslation } from 'react-i18next';
import { LocaleContext } from '@/common/locale-provider';
import moment from 'moment';

interface ILoginData {
  username: string;
  password: string;
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  color: ${defaultColor.darkGray};
  background-color: #fafafa;
  overflow: hidden;

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const InnerForm = styled.div`
  width: 500px;
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 50px 50px 20px 50px;
  border-radius: 5px;
  z-index: 1;
  &:after {
    content: "";
    width: 1px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  &.effect:after {
    content: "";
    width: 100vw;
    height: 100vh;
  }
  label {
    color: ${defaultColor.darkGray};
  }
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }
  .ant-input-affix-wrapper-lg {
    border-radius: 5px;
    padding: 0 0 0 20px;
    input {
      padding: 5px;
    }
  }
`;

const Logo = styled.div`
  margin: 0 auto;
  padding-bottom: 50px;
  text-align: center;
  img {
    width: 70%;
  }
`;
const VideoBgContainer = styled.div`
  position: fixed;
  left: 45%;
  top: 50%;
  transform: translateY(-50%);
  &:after {
    content: "";
    width: 100%;
    height: 5px;
    background-color: #fafafa;
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;
const VideoBg = styled.video`
  width: 700px;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
`;

const Signup = styled(Button)`
  color: #1d90ff;
`;

const StyledSpin = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

const getDashboardConfig = async ({ projectId }) => {
  const result = await api.getDashboardConfig({ project_id: projectId });

  return result.data;
};

const LoginPage: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const { setLocale } = useContext(LocaleContext);

  let navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProjectListModalVisible, setIsProjectListModalVisible] =
    useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [loginDatas, setLoginDatas] = useState<any>({});
  const { setUserInfo, setProjectId, projectId } = useContext(ProjectContext);
  const [logoImage, setLogoImage] = useState("");
  const [auth2step, setAuth2step] = useState("");
  const { setIsDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    setIsDarkMode(false);
    sessionStorage.removeItem("theme");
    removeSessionStorage();

    getDashboardConfig({ projectId }).then((datas) => {
      sessionStorage.setItem("systemInfo", JSON.stringify(datas));
      setLogoImage(process.env.REACT_APP_URL + datas.login_logo);
      setAuth2step(datas.auth2step_yn);
      localStorage.setItem("language", datas.lang);
      //i18n.changeLanguage(datas.lang);
      setLocale(datas.lang);
      moment.locale(datas.lang);
    });
  }, [projectId, setIsDarkMode, t, i18n, setLocale]);

  const setLogin = useCallback(
    (data, id = data.projects[0].id) => {
      instance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.token}`;
      if (typeof window !== "undefined") {
        setIsLogin(true);
        sessionStorage.setItem("accessToken", data.token);
        sessionStorage.setItem("refreshToken", data.refresh_token);
        setProjectId(id);
        setUserInfo((prev) => ({
          ...prev,
          profile: data,
        }));
      }

      navigate("/");
    },
    [navigate, setProjectId, setUserInfo]
  );

  const projectSelectHandler = useCallback(
    (item) => {
      setLogin(loginDatas, item.id);
    },
    [loginDatas, setLogin]
  );

  const onFinish = async (values: ILoginData) => {
    try {
      const { data, status } = await api.setLogin(values);

      if (status === 200) {
        if (data.projects.length > 1) {
          setLoginDatas(data);
          setProjectList(data.projects);
          setIsProjectListModalVisible(true);
        } else {
          setLogin(data);
        }
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <InnerForm className={isLogin ? "effect" : ""}>
        <Logo>
          <img src={logoImage} alt="" />
        </Logo>
        <Form
          name="normal_login"
          className="login-form"
          layout="vertical"
          initialValues={{ remember: false }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            label={t("아이디")}
            rules={[{ required: true, message: t("아이디msg")}]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t("아이디")}
            />
          </Form.Item>

          <Form.Item
            name="password"
            label={t("패스워드")}
            rules={[{ required: true, message: t("패스워드msg") }]}
          >
            <Input
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={t("패스워드")}
            />
          </Form.Item>

          {auth2step === "Y" ? (
            <Form.Item
              name="totpcode"
              label={t("TOTP인증코드")}
              rules={[
                { required: true, message: t("TOTPmsg")},
              ]}
            >
              <Input
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="TOTP Code"
              />
            </Form.Item>
          ) : null}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              size="large"
              style={{
                fontSize: "15px",
                borderRadius: "5px",
              }}
            >
              {t("로그인")}
            </Button>
          </Form.Item>
          <Form.Item style={{ marginTop: "-15px", textAlign: "right" }}>
            <Signup
              type="text"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
               {t("사용자등록")}
            </Signup>
          </Form.Item>
        </Form>
      </InnerForm>
      <VideoBgContainer>
        <VideoBg
          autoPlay
          loop
          muted
          src="/static/video/video.mp4"
          type="video/mp4"
        />
      </VideoBgContainer>

      <DesktopSignupModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      {isLogin && <StyledSpin size="large" />}

      <Modal
        width="400px"
        title={t("프로젝트선택")}
        open={isProjectListModalVisible}
        onCancel={() => setIsProjectListModalVisible(false)}
        footer={null}
      >
        <List
          bordered={false}
          dataSource={projectList}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  key="list-loadmore-edit"
                  onClick={() => projectSelectHandler(item)}
                >
                  {t("선택")}
                </Button>,
              ]}
            >
              {item.project_name}
            </List.Item>
          )}
        />
      </Modal>
    </Container>
  );
};

export default LoginPage;
